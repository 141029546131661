<template>
  <iframe :src="payUrl">

  </iframe>
</template>

<script>
import service from './api';
export default {
  data() {
    return {
      payUrl: ''
    };
  },
  methods: {
    async getPay() {
      // let { id = null, type = 1 } = JSON.parse(sessionStorage.getItem('pcPay'));
      // const res = await service.pay({ id, type });
      // this.payUrl = "https://pay.mobilebank.co.nz/api/v1.0/gateway/partners/TRGDGH/orders/202409262874696411641545/pay?time=1727332682517&nonce_str=RvLuJoOd0PuPPbE&sign=1e47bc65856908d30b8d1a53e1ff63d4ca3e8d0bbfcc687b47d52f77b6a6e6d6&redirect=http://127.0.0.1:8080/pay/success&directpay=true";
    }
  },
  mounted() {
    this.getPay();
  }
};
</script>

<style lang="scss" scoped>
</style>
